import { render, staticRenderFns } from "./ViewHubs.vue?vue&type=template&id=06f20210&scoped=true&"
import script from "./ViewHubs.vue?vue&type=script&lang=js&"
export * from "./ViewHubs.vue?vue&type=script&lang=js&"
import style0 from "./ViewHubs.vue?vue&type=style&index=0&id=06f20210&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f20210",
  null
  
)

export default component.exports