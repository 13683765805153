<template>
  <div>
    <div v-if="zipNotFound" id="hubs-header">
      <img src="@/assets/pas-livraison-icon-2.svg" class="big-image" />
      <h2>{{ $t('hubs.title') }}</h2>
    </div>
    <div id="hubs-title">
      <h2 v-if="zipNotFound">{{ $t('hubs.withoutZip') }}</h2>
      <h2 v-else>{{ $t('hubs.withZip') }}</h2>
      <img src="@/assets/pas-livraison-shop-icon.png" class="big-image" />
      <div>
        <div v-for="(city, index) in cities" :key="city.id" class="city-container" :class="{ last: index === cities.length -1 }">
          <h3>{{ city.name }}</h3>
          <router-link :to="getUrl(city)">
            <button>
              {{ $t('hubs.go') }}
            </button>
          </router-link>
          <div v-for="hub in city.hubs" :key="hub.id" class="hub-container">
            <div class="hub-content">
              {{ hub.name }}, {{ hub.coordinate.street }}
              <span v-if="hub.coordinate.city !== city.name">, {{ hub.coordinate.city }}</span>
              <br />
              <ul>
                <template v-for="(line, hindex) in hub.getOpeningHoursGrouped()">
                  <li v-if="line.hours.length" :key="hindex">
                    <strong>
                      {{ $t(`dates.short.${line.firstDay}`) }}
                      <span v-if="line.firstDay !== line.lastDay"> - {{ $t(`dates.short.${line.lastDay}`) }}</span>
                    </strong>
                    : {{ line.hours.join(' / ') }}
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewHubs',
  props: {
    partnerId: { type: String }
  },

  data: () => ({
    hubs: [],
    loading: false
  }),

  computed: {
    zipNotFound() {
      return !!this.$route.query.notfound
    },

    partners() {
      return this.$store.getters['partner/partners']
    },

    cities() {
      const hubs = this.hubs.filter(hub => !this.partnerId || hub.partners.indexOf(this.partnerId) !== -1)

      return Object.values(hubs.reduce((acc, hub) => {
        const partnerId = hub.partners[0]
        if (!acc[partnerId]) {
          const partner = this.partners.find(p => p.id === partnerId)
          acc[partnerId] = {
            id: partnerId,
            name: partner?.name,
            hubs: []
          }
        }
        acc[partnerId].hubs.push(hub)
        return acc
      }, {}))
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    load() {
      this.loading = true
      return this.$store.dispatch('hub/searchByZip', { zip: null })
        .then(hubs => (this.hubs = hubs.filter(hub => !hub.homeOnly)))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    getUrl(city) {
      if (this.$route.query.categoryId) {
        return { name: 'shops', params: { partnerId: city.id }, query: { categoryId: this.$route.query.categoryId } }
      }
      return { name: 'partner', params: { partnerId: city.id } }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../assets/styles/youpaq.scss';

#hubs-header {
  position: relative;
  background-color: var(--yellow-color);
  padding-bottom: 75px;
  @include media-breakpoint-up(md) {
    padding: 40px 0px 100px 0px;
  }

  .big-image {
    display: block;
    margin: 0 auto;
    max-width: 300px;
  }
}
h2 {
  font-weight: 900;
  font-size: 30px;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  color: white;
  padding: 0 20px;
  margin-top: 50px;
  display: inline-block;
  max-width: 800px;
  @include media-breakpoint-up(md) {
    font-size: 35px;
    margin-bottom: 60px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 40px;
  }
}

#hubs-title {
  h2 {
    color: var(--yellow-color);
    max-width: 1000px;
    margin: 3em auto;
    margin-bottom: 1em;
  }

  .big-image {
    display: block;
    margin: 0 auto;
    max-width: 170px;
    margin-bottom: 3em;
  }
}

.city-container {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 4em;
  border-top: 1px solid var(--yellow-color);
  padding-top: 10px;

  &.last {
    margin-bottom: 200px;
  }

  button {
    flex-shrink: 1;
    background-color: var(--green-color);
    border: none;
    border-radius: 10px;
    padding: 10px 25px;
    color: white;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    box-shadow: 1px 1px 4px #00000069;
    margin-bottom: 20px;
    white-space: nowrap;
  }

  .hub-container {
    margin-bottom: 1em;
    display: flex;
    padding: 10px;

    &:hover {
      background-color: #eee;
    }

    .hub-content {
      flex-grow: 1;
      text-align: left;
    }
  }
}
</style>
